<template>
    <div class="shotPhoto">
        <van-nav-bar title="证件照" left-arrow fixed @click-left="goBack" @click-right="isDropdownMenu= !isDropdownMenu">
            <template #right>
                <van-icon size="22" name="apps-o"/>
            </template>
        </van-nav-bar>
        <div class="downMenuModal" v-if="isDropdownMenu" @click="isDropdownMenu = false">
            <div v-if="isDropdownMenu" class="dropdownMenu">
                <div class="menuItem" @click="$router.push('/')">
                    <span>首页</span>
                </div>
                <div class="menuItem" @click="$router.push('/xuanke')">
                    <span>选课中心</span>
                </div>
                <div class="menuItem" @click="$router.push('/tiku')">
                    <span>题库</span>
                </div>
                <div class="menuItem" @click="$router.push('/homeLiveList')">
                    <span>直播</span>
                </div>
                <div class="menuItem" @click="$router.push('/learning')">
                    <span>学习中心</span>
                </div>
                <div class="menuItem" @click="downApp">
                    <span>下载APP</span>
                </div>
            </div>
        </div>
        <div class="content-box">
            <div class="tips-box">
                <img src="../../assets/images/photo_case.png" />
                <div class="tips-child">
                    <div class="first-box">拍摄提示</div>
                    <div>光线充足不逆光</div>
                    <div>人脸居中，正对镜头</div>
                    <div>穿深色衣服在白墙前</div>
                    <div>露出眉毛和耳朵，面部无遮挡</div>
                </div>

            </div>
            <div class="sepc-box">
                <div class="sepc-child">
                    <div style="width:100%;height:10px"></div>
                    <div class="first-box">规格详情</div>
                    <div style="margin-top:7px">
                        <span class="detail-left">像素尺寸：</span>
                        <span class="detail-right">{{this.listData.pixelSize}}</span>
                    </div>
                    <div style="margin-top:7px">
                        <span class="detail-left">文件大小：</span>
                        <span class="detail-right">{{this.listData.fileSize}}</span>
                    </div>
                    <div class="back-box" style="margin-top:7px">
                        <span class="detail-left">背景色：</span>
                        <span class="bgcolor-box" :style="{'background':this.listData.bgColor}"></span>
                    </div>
                </div>
            </div>
            <div class="btn-box">
                <div class="btn-right">
                    <input type="file" id="photo"  accept="image/*" @change="uploadPic"  />
                    <div @click="openphoto">打开相册或者拍照</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { makePhotoList } from '@/api/photo.js';
export default {
        name: "shotPhoto",
        data() {
            return {
               listData:{},
               isDropdownMenu:false,
                
            }
        },
        mounted() {
            //this.$toast.resetDefaultOptions("loading");
            this.init();
        },

        methods: {
            downApp(){
                window.location.href = 'https://www.saen.com/app/';
            },
            openphoto(){
                const input = document.querySelector('#photo')
                input.click();
            },
            makePhoto(file){
                console.log("33333");
                this.$toast.loading({
                    message: "加载中...",
                    duration: 0,
                    className:"photoList",

                });
                let obj = new FormData()
                obj.append('file',file.replace(/^data:image\/\w+;base64,/, ""))
                obj.append('width',this.listData.width)
                obj.append('height',this.listData.height)
                obj.append('bgColor','#FFFFFF')
                obj.append('colorName','saen')
                obj.append('fairLevel',0)
                obj.append('photoId',this.listData.id)
                makePhotoList(obj).then((res)=>{
                    if(res.data.code == 0){
                        
                        localStorage.setItem('detail_makePhoto',JSON.stringify(res.data.data));
                        sessionStorage.setItem('makePhotoBase64',file.replace(/^data:image\/\w+;base64,/, ""));
                        this.$toast.clear();
                        this.$router.push({
                            path:'/generate'
                        })
                    }
                })
            },
            uploadPic(event) {
                let inputDOM = event.target
                let _this = this;
                console.log("11111");
                var reader = new FileReader()
                reader.readAsDataURL(inputDOM.files[0])
                reader.onload = function (e) {
                    event.target.value = '';
                    console.log("22222");
                    var base = e.target.result;
                    // let res = base.split(',')
                    // _this.imgsrc = base;
                    _this.makePhoto(base,_this);
                }

                inputDOM.value = null //将input置空 否则上传相同文件无反应 (不过置空后 29行的打印 就看不到 event.target.files 文件数据 30行直接打印可以看到文件数据（可以先注释此行看下数据--就是图片文件）   )
            },
            goBack() {
              this.$router.go(-1);
            },
            init(){
                const obj = localStorage.getItem('shotTypeDetail');
                this.listData = JSON.parse(obj);
               
            },
            setPhotoDetail(item){
               
                

            },
            
            
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#photo {
  display: none;
}
.downMenuModal{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        .dropdownMenu{
            position: fixed;
            right: 10px;
            top:44px;
            background-color: #fff;
            z-index: 999;
            width: 100px;
            min-height: 200px;
            box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);

            .menuItem{
                height: 40px;
                line-height: 40px;
                font-size: 13px;
                padding-left: 20px;
            }
            .menuItem5{
                color: #ef8f27;
            }
        }
    }
.shotPhoto{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
   
}
.content-box{
    width: 100%;
    height: calc(100vh - 46px);
    margin-top: 46px;
    .btn-box{
        margin-top: 20px;
        width: calc(100% -  60px);
        margin-left: 30px;
        height: 40px;
        display: flex;
       
        .btn-right{
            flex:1;
            div{
                font-size: 14px;
                width: 100%;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background: #5d7dff;
                color: #ffffff;
                border-radius: 20px;
               
            }

        }
    }
    .detail-left{
        color: #ada1a1;
    }
    .detail-right{
        color: #5a5757;
        font-weight: 600;
    }
    .sepc-box{
        width: calc(100% -  60px);
        margin-left: 30px;
        height: 120px;
        background-color: #efeaea;
        border-radius: 5px;
        margin-top: 15px;
        .bgcolor-box{
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        .sepc-child{
           
            margin-left: 10px;
            .back-box{
                display: flex;
                flex-direction: row;
            }

        }
        .first-box{
            color: #ecb213;
            font-size: 15px;
            font-weight: bold;
           
        }
        
    }
    .tips-box{
        width: calc(100% -  60px);
        margin-left: 30px;
        height: 150px;
        background-color: #efeaea;
        border-radius: 5px;
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        img{
            width: 90px;
            height: 120px;
            margin-left: 10px;
            margin-top: 15px;
        }
        .tips-child{
            margin-top: 10px;
            margin-left: 10px;
            .first-box{
                color: #ecb213;
                font-size: 15px;
                font-weight: bold;

            }
            .other-box{
                font-size: 14px;
                font-weight: 500;

            }
            div{
                margin-top: 5px;

            }
            
        }
    }
    
}
</style>
