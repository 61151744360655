import { render, staticRenderFns } from "./shotPhoto.vue?vue&type=template&id=6544ee17&scoped=true&"
import script from "./shotPhoto.vue?vue&type=script&lang=js&"
export * from "./shotPhoto.vue?vue&type=script&lang=js&"
import style0 from "./shotPhoto.vue?vue&type=style&index=0&id=6544ee17&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6544ee17",
  null
  
)

export default component.exports